
export default {
  name: 'SghCmsSubMenuList',
  props: {
    title: {
      type: String,
    },
    multiColumn: {
      type: Boolean,
      default: false,
    },
  },
}
