export const getters = {
  facets: state => state.facets || {},
  facetsCount: state => state.facetsCount || {},
  facetsGroupNames: state => state.facetsGroupNames || {},
  facetsSelected: state => state.facetsSelected || {},
  filteredCMSBanners: state => state.filteredCMSBanners || '',
  filterOptionsList: state => state.facets.filterOptionsList || [],
  idsFiltersCheckedList: state => state.facetsSelected.idsFiltersCheckedList || [],
  extraFacets: state => state.facetsSelected.extraFacets || [],
  isFacetsFirstLoad: state => state.isFacetsFirstLoad || false,
  isFilterCategoryLabel: state => state.isFilterCategoryLabel || false,
  isFilterCategoryHidden: state => state.isFilterCategoryHidden || {},
  nextPageURL: state => state.nextPageURL || '',
  pagination: state => state.pagination || {},
  products: state => state.products || [],
  attributes: state => state.attributes || {},
  ui: state => state.ui || {},
  firstNewProductsIndex: state => state.firstNewProductsIndex || -1,
  facetsToggleList: state => state.facets.facetsToggleList || {},
  plpLoadingFacets: state => state.ui.plpLoadingFacets || false,
}
