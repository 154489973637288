import {
  getMinicartNormalized,
  getMinicartPromotionNormalized,
} from '../normalizer'
import { updateCounterMinicart } from '../../libs/utils/utilsMinicart'
import wcs from './index'
import axios from 'axios'

//NOTE: this file is used in other part of the website
export const getMinicart = async (minicartProducts = null, productId) => {
  const useMockData = wcs.getEnvConfig('useMockData') || false
  if (useMockData) {
    const basePath = window.PATH_PREFX || '/'
    await $.getJSON(`${basePath}mocks/wcs-pdp-minicart.json`, function(data) {
      localStorage.setItem('itemsArray', JSON.stringify(data))
    })
  }

  const minicartProductsArray =
    minicartProducts || JSON.parse(window.localStorage.getItem('itemsArray'))
  return getMinicartNormalized(minicartProductsArray, productId)
}



export const getMinicartPromotion = async (upcProduct) => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const useMockData = wcs.getEnvConfig('useMockData') || false
  const queryUpc = upcProduct ? `&upc=${upcProduct}` : ''
  const { storeId, langId } = wcs.getStoreConfig()
  const endpointPromo =
    useMockData == 'true'
      ? wcs.getEndPoint('getMinicartPromotion')
      : `${domain}/wcs/resources/store/${storeId}/espot/MINICART_PROMO_SUGGESTION?langId=${langId}${queryUpc}`

  let promo = {}
  await axios.get(endpointPromo).then(
    response => {
      promo = response
    },
    error => {
      console.log(error)
    }
  )

  return getMinicartPromotionNormalized(promo)
}

export const getNumberitem = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const useMockData = wcs.getEnvConfig('useMockData') || false
  const { storeId } = wcs.getStoreConfig()
  let counter = 0
  const endpointCounter = `${domain}/wcs/resources/store/${storeId}/minicart?_${new Date().getTime()}`

  useMockData
    ? (counter = Math.round(Math.random() * 5))
    : await axios.get(endpointCounter).then(
        response => {
          counter = response.data.numberOfItems
          updateCounterMinicart(counter)
        },
        error => {
          console.log(error)
        }
      )

  return counter
}
