
  import AnchorLayout from '../components/trends-anchor-buttons-anchor-layout.vue'
  
  export default {
    name: 'sgh-cms-trends-anchor-buttons-chip',
  
    components: {
      AnchorLayout,
    },
    
    props: {
      text: {
        type: String,
        default: '',
      },
      textStyle: {
        type: String,
        default: '',
      },
    },
  }
