<script lang="ts">
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'
import SghCmsSubMenuList from '@components/cms-modules/ddm-modules/sub-components/sub-menu-list.vue'
import SghSubMenu from '@components/navigation/sub-menu.vue'
import SghMenuItem from '@components/navigation/menu-item.vue'
import SghCmsDdmLayout from '@components/cms-modules/ddm-modules/layouts/ddm-layout.vue'
const { mapGetters: mapGettersDdm } = createNamespacedHelpers('ddm')
const { mapActions: mapActionsUi,mapGetters: mapGettersUi } = createNamespacedHelpers('ui')
import {
  ACTION_SUBMENU_SECTION_VISIBLE,
  ACTION_UPDATE_SUBMENU_SECTIONS,
} from '@store/actions/ui'
export default defineComponent({
  name: 'SubMenusOutlet',
  components: {
    SghCmsDdmLayout,
    SghMenuItem,
    SghSubMenu,
    SghCmsSubMenuList,
  },
  data() {
    return {
      selectedMenuId: null,
    }
  },
  computed: {
    ...mapGettersDdm(['mainMenu']),
    ...mapGettersUi(['menu']),
    submenus() {
      return (
        this.mainMenu?.map(item => {
          if(!!item.subMenu) {
            return {
              item:item.subMenu,
              id: item.id,
              label: item.label,
            }
          }

        }).filter(item => !!item) || []
      )
    },
    isMobile() {
      return this.$mq === 'tablet' || this.$mq === 'tabletLandscape'
    },

  },
  methods: {
    ...mapActionsUi({
      toggleSubMenuId: ACTION_UPDATE_SUBMENU_SECTIONS,
      toggleMobileSubMenuVisible: ACTION_SUBMENU_SECTION_VISIBLE,
    }),
    afterLeave() {
      this.toggleSubMenuId({ sectionId: this.selectedMenuId })
    },
    onBackBtnClicked(id) {
      this.selectedMenuId = id
      this.toggleMobileSubMenuVisible({visible: false})
    },
  },
})
</script>
<template>
  <transition name="slide-fade" mode="out-in" @after-leave="afterLeave">
    <div class="sgh-submenus-outlet">
      <SghSubMenu
        v-for="submenu in submenus"
        v-show="menu.openSubMenus.includes(submenu.id)" :id="submenu.id"
        :key="submenu.id"
        :expanded="menu.openSubMenus.includes(submenu.id)"
        :label="submenu.label"
        @back="onBackBtnClicked(submenu.id)"
      >
        <SghCmsDdmLayout>
          <SghCmsSubMenuList
            v-for="(menuList, index) in submenu.item.menuLists"
            :key="index"
            :title="menuList.label"
          >
            <SghMenuItem
              v-for="(itemMenu, menuItemIndex) in menuList.items"
              :id="itemMenu.id"
              :key="submenu.id + itemMenu.id + '-' + menuItemIndex"
              :master-id="itemMenu['data-master-id']"
              :is-high-lighted="itemMenu.highlighted"
              :is-shop-all="itemMenu.isShopAll"
              :label="itemMenu.label"
              :url="itemMenu.url"
              :badge="itemMenu.badge"
            ></SghMenuItem>
          </SghCmsSubMenuList>
        </SghCmsDdmLayout>
      </SghSubMenu>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
@import '@styles/variables';

</style>

<style scoped lang="scss">
.sgh-submenus-outlet {
  z-index: 20;
}
// TRANSITION ANIMATION
.slide-fade-move,
.slide-fade-enter-active,
.slide-fade-leave-active {

  transition: transform 0.8s;
  transform: translate3d(0, 0, 0);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translate3d(100%, 0, 0);
}
</style>