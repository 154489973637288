import { getCurrentCountry } from '../utils/currentCountry'
import wcs from './index'
import axios from 'axios'

export const includePlacement = async (parameters, name) => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { locale, storeId, langId, catalogId } = wcs.getStoreConfig()
  const userClusterValue = window.algoliaConfig.isEarlyAccessParam ? 'EarlyAccess' : 'None'
  const categoriesParam = parameters?.categories ? `,"categories":"${parameters.categories}"` : '';
  const urlParams = new URLSearchParams(window.location.search)
  const userVariant = urlParams.get('userVariant')
  const previewDate = urlParams.get('previewDate')
  const url = `${domain}/wcs/resources/store/${storeId}/cms/include?exposeError=false&var=${parameters?.name ??
    ''}&storeId=${storeId}&langId=${langId}&catalogId=${catalogId}&placement=${parameters?.placement ??
    ''}&view=${parameters?.view ?? ''}&pageId=${parameters?.pageId ??
    ''}&categoryId=${parameters?.categoryId ?? ''}&productId=${parameters?.productId ??
    ''}&parameter={"USER_CLUSTER":"${userClusterValue}"${categoriesParam}}&locale=${locale}&userVariant=${userVariant || ''}&previewDate=${previewDate || ''}`
  let data = {}
  const headers = parameters?.headers ? parameters.headers : null
  const axiosDataType = parameters?.axiosDataType ? parameters.axiosDataType : null

  try {
    data = await axios.get(url,{
      headers,
      data:axiosDataType,
    })
    sessionStorage.setItem(name + '_' + getCurrentCountry(), JSON.stringify(data.data))
    return data.data
  } catch (error) {
    if (error.response) {
      console.log(error.response.data)
    } else {
      return 'Error'
    }
  }
}
