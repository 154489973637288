import { render, staticRenderFns } from "./cms-swiper-tiles.vue?vue&type=template&id=3429f4ba&scoped=true"
import script from "./cms-swiper-tiles.vue?vue&type=script&lang=js"
export * from "./cms-swiper-tiles.vue?vue&type=script&lang=js"
import style0 from "./cms-swiper-tiles.vue?vue&type=style&index=0&id=3429f4ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3429f4ba",
  null
  
)

export default component.exports