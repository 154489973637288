import './publicpath.js'

import Vue from 'vue'
import Vuex from 'vuex'
import VueMq from 'vue-mq'
import VueMultianalytics from './libs/vue-multianalytics/index.js'
import TealiumTrackingModule from './libs/tracking/tealium.js'
import VScrollLock from 'v-scroll-lock'
import { LazyLoad } from './directives/lazy-load.js'
import createStore from './store/index.js'
import { UPDATE_LABELS } from './store/mutations/labels.js'
import { getLabelsJSON } from './libs/data.js'
import { breakpoints, GOOGLE_MAP_API_KEY } from './libs/utils/const.js'
import * as GmapVue from 'gmap-vue'
import GmapCluster from 'gmap-vue/dist/components/cluster'
import { animationHeaders } from './libs/utils/headerUtils.js'
import router from './../router'
import i18n, { DEFAULT_LOCALE } from './i18n-setup.js'
import { formatPrice } from './libs/utils/string.js'

const ExposedSGHApi = require('./exposed-api/exposed-api.js')
if (!window.externalInvocationMethodMapping) {
  window.externalInvocationMethodMapping = {}
}

Vue.use(Vuex)
Vue.directive('lazy-load', LazyLoad)
Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})
Vue.use(VueMq, {
  breakpoints,
})
Vue.use(VScrollLock)
Vue.use(GmapVue, {
  load: {
    key: GOOGLE_MAP_API_KEY,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'gmap-vue/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
})
Vue.component('GmapCluster', GmapCluster)

const store = createStore(Vuex)
window.Vue = window.Vue || Vue
ExposedSGHApi.registerExposedMethods(store)

// Hydrate inline labels
store.commit(`${UPDATE_LABELS}`, getLabelsJSON())

const $els = document.querySelectorAll('[data-vue="app"]')

Vue.filter('toCurrency', function(value, customCurrency = null, customLocale = null) {
  const DEFAULT_CURRENCY = 'USD'

  let currency = null
  let locale = null

  //use currency and local as custom parameters
  if (customCurrency && customLocale) {
    currency = customCurrency
    locale = customLocale == 'es_ES' ? 'de-DE' : customLocale.replace('_', '-')
  }
  //use currency and local by actual store
  else {
    currency =
      window.wcs_config && window.wcs_config.currency
        ? window.wcs_config.currency
        : DEFAULT_CURRENCY
    locale = window.wcs_config ? window.wcs_config.locale.replace('_', '-') : DEFAULT_LOCALE
  }

  // Detect Safari
  let safariAgent = navigator.userAgent.indexOf('Safari') > -1
  let chromeAgent = navigator.userAgent.indexOf('Chrome') > -1
  // Discard Chrome since it also matches Safari
  if (chromeAgent && safariAgent) safariAgent = false
  const currencyDisplayValue = safariAgent ? 'symbol' : 'narrowSymbol'

  let formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: currencyDisplayValue,
  })
  return formatter.format(parseFloat(formatPrice(value), 10))
})

if (location.href.includes('myaccount')) {
  $els.forEach($el => {
    new Vue({
      el: $el,
      i18n,
      store,
      router,
      computed: {
        mainClasses() {
          return {
            loaded: true,
          }
        },
      },
    })
  })  
} else {
  $els.forEach($el => {
    new Vue({
      el: $el,
      i18n,
      store,
      computed: {
        mainClasses() {
          return {
            loaded: true,
          }
        },
      },
    })
  })  
}


// Setup Tracking Library
VueMultianalytics.addCustomModule('tealium', TealiumTrackingModule)
Vue.use(VueMultianalytics, {
  modules: {
    tealium: {
      /* module config */
    },
  },
})



const textActionsModule = () => {
  let cmsTextModuleActions = [...document.querySelectorAll('.cms-text-module__actions')]
  cmsTextModuleActions.forEach(e => {
    let cmsTextModuleActionsArray = [...e.children]
    let max = Math.max(...cmsTextModuleActionsArray.map(obj => obj.offsetWidth))

    cmsTextModuleActionsArray.forEach(e => {
      e.style.width = `${max}px`
    })
  })
}

window.onload = function() {
  window.requestAnimationFrame(textActionsModule)
}

document.addEventListener('DOMContentLoaded', function() {
  if ('scrollRestoration' in window.history) window.history.scrollRestoration = 'auto'
  animationHeaders()
  footerMobile()
})

const footerMobile = () => {
  const panelLinks = document.querySelectorAll('.sgh-footer .panel-heading .collapsed')

  panelLinks.forEach(link => {
    link.addEventListener('click', function(event) {
      event.preventDefault()
      const href = this.getAttribute('href')
      if (!href?.startsWith('#')) return

      const targetElement = document.querySelector(href)
      if (!targetElement) {
        console.warn(`Element with id "${href}" not found.`)
        return
      }

      panelLinks.forEach(otherLink => {
        const otherHref = otherLink.getAttribute('href')
        if (otherHref !== href) {
          const otherTarget = document.querySelector(otherHref)
          if (otherTarget?.classList.contains('in')) {
            otherTarget.classList.remove('in')
            otherLink.classList.add('collapsed')
          }
        }
      })

      if (targetElement.classList.contains('in')) {
        targetElement.classList.remove('in')
        event.currentTarget.classList.add('collapsed')
      } else {
        targetElement.classList.add('in')
        event.currentTarget.classList.remove('collapsed')
        event.currentTarget.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    })
  })
}

window.triggerVueMethod = function(methodName, ...args) {
  if (window.externalInvocationMethodMapping[methodName]) {
    window.externalInvocationMethodMapping[methodName].apply(null, args)
  }
}

window.toogleText = (
  bannerCodeId,
  completeText,
  excerptLength,
  excerptOverflow,
  readLessText,
  readMoreText,
  textSeparator
) => {
  if (document.getElementById('contentText-' + bannerCodeId).innerText == completeText) {
    completeText = completeText.substr(0, completeText.lastIndexOf(textSeparator, excerptLength))
    document.getElementById('moreLessCta-' + bannerCodeId).innerText = readMoreText
    return (document.getElementById('contentText-' + bannerCodeId).innerText =
      completeText + excerptOverflow)
  } else {
    document.getElementById('moreLessCta-' + bannerCodeId).innerText = readLessText
    return (document.getElementById('contentText-' + bannerCodeId).innerText = completeText)
  }
}
