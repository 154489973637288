
export default {
  name: 'SghCmsDdmLayout',
  props: {
    columns: {
      type: Number,
      default: 4,
    },
  },
}
