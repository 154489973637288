
import SghRecovery from './components/recovery/sgh-recovery.vue'

export default {
  name: 'forgotPassword',
  components:{
    SghRecovery,
  },
  props: {
    sitekey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      success: false,
      mail: '',
    }
  },

  computed: {
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
  },

  mounted() {
    this.$root.$on('isSuccess', mail => {
      this.success = !this.success
      this.mail = mail
    })
  },
}
