
import { loadSectionTranslations } from '../../i18n-setup'
import { ACTION_UPDATE_SEARCH_OVERLAY_OPEN } from '@store/actions/search'
import { createNamespacedHelpers } from 'vuex'
import qs from 'qs'
import isEmpty from 'lodash/isEmpty'
import { deleteParamUrl } from '@libs/utils/url'

const { mapActions, mapGetters } = createNamespacedHelpers('search')
export default {
  name: 'sgh-navigation-right-part',
  props: {
    searchEnabled: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      searchOpened: false,
      searchOverlayIsVisible: false,
      bodyEl: document.querySelector('body'),
      fakeInputHiddenClass: 'hide-fake-input',
      buttonOffClass: 'buttons-off',
      searchInputFocused: false,
    }
  },
  computed: {
    ...mapGetters({
      search: 'search',
      searchTerm: 'searchTerm',
    }),
  },
  methods: {
    ...mapActions({
      updateOverlayIsOpen: ACTION_UPDATE_SEARCH_OVERLAY_OPEN,
    }),
    handleCloseClick() {
    this.updateOverlayStatus(false)

   
    this.bodyEl.classList.remove('fake-input-active', 'hide-fake-input')
  },
    toggleSearchLayout(flag) {
      this.preventDoubleClick()
      if (flag) {
        this.searchOverlayIsVisible = this.searchOpened = true
        this.bodyEl.classList.add('overflow-hidden')
        this.bodyClassManipulation('add', this.fakeInputHiddenClass, 1000)
      } else {
        deleteParamUrl('q')
        this.searchOpened = false
        this.$refs?.overlay?.$el.classList.remove('in')
        this.bodyEl.classList.remove('overflow-hidden')
        this.bodyClassManipulation('remove', this.fakeInputHiddenClass)
        setTimeout(() => {
          this.searchOverlayIsVisible = false
          this.$emit('closeFakeInput')
        }, 500)
      }
    },
    updateOverlayStatus(isOpen) {
      this.updateOverlayIsOpen(isOpen)
    },
    preventDoubleClick() {
      this.bodyClassManipulation('remove', this.buttonOffClass, 1200, true)
    },
    bodyClassManipulation(operationName, classString, delay, doubleCallFlag) {
      const transformedName = operationName === 'add'
      if (delay) {
        setTimeout(() => {
          this.bodyEl.classList.toggle(classString, transformedName)
        }, delay)
      } else {
        this.bodyEl.classList.toggle(classString, transformedName)
      }

      if (delay && doubleCallFlag) {
        this.bodyEl.classList.toggle(classString, !transformedName)
      }
    },
    setScrollPosition() {
      this.$refs.overlay.$el.scrollTop = 0
    },

    setTabIndexForLinksInMainMenuCenter(value) {
      const links = document.querySelectorAll('.main-menu-center a')
      links.forEach(link => {
        link.setAttribute('tabindex', value)
      })
    },
    setTabIndexForLinksInMainMenuRight(value) {
      const links = document.querySelectorAll('.main-menu-right a')
      links.forEach(link => {
        if (link.dataset.elementId && link.dataset.elementId.includes('MainNav')) {
          link.setAttribute('tabindex', value)
          const spans = link.querySelectorAll('span')
          spans.forEach(span => {
            span.setAttribute('tabindex', value)
          })
        }
      })
    },
  },
  watch: {
    search: {
      // this deep watching created for tracking changes inside  internal objects
      handler(newVal, oldVal) {
        this.toggleSearchLayout(newVal.overlayIsOpen)
      },
      deep: true,
    },
    //Managed the display of items via tabindx when the search is open
    searchOpened(newVal) {
        if (newVal) {
          this.setTabIndexForLinksInMainMenuCenter(-1)
          this.setTabIndexForLinksInMainMenuRight(-1)
        } else {
          this.setTabIndexForLinksInMainMenuCenter(0)
          this.setTabIndexForLinksInMainMenuRight(0)
        }
    },
  },
  mounted() {
    loadSectionTranslations('my-account-new')
    const currentParams = qs.parse(decodeURIComponent(window.location.search), {
      ignoreQueryPrefix: true,
    })
    if (!isEmpty(currentParams.q)) {
      this.updateOverlayStatus(true)
    }
  },
}
