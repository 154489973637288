
import DdmImage from '@components/cms-modules/ddm-modules/sub-components/ddm-image.vue'
import { parseCmsContentText } from '@libs/utils/cms'

export default {
  name: 'SghCmsCollection',
  components: { DdmImage },
  props: {
    placement: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    images() {
      return this.placement?.images
    },
  },
  methods: { parseCmsContentText },
}
