
import AnchorLayout from '../components/trends-anchor-buttons-anchor-layout.vue'
import { createNamespacedHelpers } from 'vuex'
import { ACTION_SET_TREND_ANCHOR_BUTTONS_ACTIVE_ITEM } from '@store/actions/ui'

const { mapActions: mapActionsUi } = createNamespacedHelpers('ui')

export default {
  name: 'SghCmsTrendsAnchorButtonsContent',

  components: {
    AnchorLayout,
  },

  props: {
    type: {
      type: String,
      default: 'text',
      validator: (value) => ['text', 'image', 'chip', 'text-background', 'text-icon'].includes(value),
    },
    text: {
      type: String,
      default: '',
    },

    textStyle: {
      type: String,
      default: '',
    },
    textAlignment: {
      type: String,
      default: '',
    },
    imgSrc: {
      type: String,
      default: '#',
    },
    alt: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isScrolled: false,
      backgroundColorClass: '',
    }
  },

  computed: {
    isMobile() {
      return this.$mq === 'tablet' || this.$mq === 'tabletLandscape'
    },
    contentTextClass() {
      switch (this.currentType) {
        case 'text': return 'sgh-cms__trends-anchor-buttons--anchor-text ' + this.textStyle
        case 'chip': return 'sgh-cms__trends-anchor-buttons--chip ' + this.textStyle
        case 'text-background': return 'sgh-cms__trends-anchor-buttons--text-background ' + this.textStyle
        case 'image': return 'sgh-cms__trends-anchor-buttons--figure ' + this.textStyle
        case 'text-icon': return 'sgh-cms__trends-anchor-buttons--text-icon ' + this.textStyle
        default: return ''
      }
    },
    textAlignmentClass() {
      switch (this.textAlignment) {
        case 'center': return 'content-center'
        case 'top': return 'content-start'
        default: return 'content-end'
      }
    },
    currentType() {
      if (this.isScrolled && (this.type === 'image' || this.type === 'text-background')) {
        return 'chip'
      }
      return this.type
    },
    backgroundImageDynamicStyle() {
      return {
        backgroundImage: `url(${this.imgSrc})`,
        opacity: this.isScrolled ? 0 : 1,
        transition: 'opacity 0.5s ease-in-out',
      }
    },
    pClass() {
      return {
        'sgh-cms__trends-anchor-buttons--figcaption bottom-0 pb-4 px-4': this.currentType === 'image',
        [`${this.textAlignmentClass} h-full pb-4 px-4`]: !this.isScrolled,
      }
    },
    containerBackgroundStyle() {
      return {
        backgroundColor: this.backgroundColor,
      }
    },
    baseLayoutClass() {
      const isImageOrTextBackground = this.type === 'image' || this.type === 'text-background'
      const isScrolledImageOrTextBackground = this.isScrolled && isImageOrTextBackground
      return isImageOrTextBackground || isScrolledImageOrTextBackground
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    ...mapActionsUi({
      setActiveAnchor: ACTION_SET_TREND_ANCHOR_BUTTONS_ACTIVE_ITEM,
    }),
    handleScroll() {
      const currentScrollPosition = window.scrollY
      const scrollPosition = currentScrollPosition + window.innerHeight / 2
      const elementHref = this.$attrs.href
      const hrefBase = elementHref.split('#')[0]
      const hrefHash = elementHref.split('#')[1]
      const origin = window.location.origin
      const pathName = window.location.pathname
      const isSamePage = hrefBase === `${origin}${pathName}`

      if (hrefHash && isSamePage) {
        const elementId = document.getElementById(hrefHash)
        const elementPosition = elementId.getBoundingClientRect()
        const elementTop = elementPosition.top + currentScrollPosition
        const elementBottom = elementTop + elementPosition.height
        if (scrollPosition >= elementTop && scrollPosition <= elementBottom) {
          this.setActiveAnchor(this.$children[0]._uid)
        }
      }

      if (currentScrollPosition > 0) {
        this.isScrolled = true

        const regex = /cms-custom-color-[\w-]+/
        const match = this.textStyle.match(regex)

        if ((this.type === 'image' || this.type === 'text-background')) {
          if (match) {
            this.backgroundColorClass = match[0]
          } else {
            if (this.textStyle.includes('cms-custom-text-color-white')) {
              this.backgroundColorClass = 'cms-custom-color-black'
            } else if (this.textStyle.includes('cms-custom-text-color-black')) {
              this.backgroundColorClass = 'cms-custom-color-white'
            }
          }
        }
      } else {
        this.isScrolled = false
        this.backgroundColorClass = ''
        this.setActiveAnchor(0)
      }
    },
  },
}
