
import { createNamespacedHelpers } from 'vuex'
import { ACTION_SET_TREND_ANCHOR_BUTTONS_ACTIVE_ITEM } from '@store/actions/ui'
import { getCurrentCountry } from '@libs/utils/currentCountry'

const { mapActions: mapActionsUi, mapGetters: mapGettersUi } = createNamespacedHelpers('ui')

export default {
  name: 'SghCmsTrendsAnchorButtonsAnchorLayout',
  props: {
    href: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGettersUi(['cmsModules']),
    isSelected() {
      return this.cmsModules.selectedTrendsAnchorsButton === this._uid
    },
  },
  methods: {
    ...mapActionsUi({
      setActiveAnchor: ACTION_SET_TREND_ANCHOR_BUTTONS_ACTIVE_ITEM,
    }),
    clickAnchor(event){
      event.preventDefault()
      this.setActiveAnchor(this._uid)

      const href = this.href
      const origin = window.location.origin
      const pathName = window.location.pathname
      const hrefBase = href.split('#')[0]
      const isSamePage = hrefBase === `${origin}${pathName}`

      const currentCountry = getCurrentCountry()
      const fullUrl = `${origin}/${currentCountry}${href}`

      if (href.startsWith('#')) {
        window.location.href = fullUrl
      } else {
        window.location.href = href
      }

      const anchorId = href.substring(1)
      const element = document.getElementById(anchorId)

      if (element && isSamePage) {
        const offset = 250
        const bodyRect = document.body.getBoundingClientRect().top
        const elementRect = element.getBoundingClientRect().top
        const elementPosition = elementRect - bodyRect
        const offsetPosition = elementPosition - offset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    },
  },
}
