
import { parseCmsContentText } from '@libs/utils/cms'
export default {
  name: 'DdmImage',
  data() {
    return {
      isImageLoaded: false,
      skeletonWidth: this.isCombo ? 178 : 387,
      skeletonHeight: this.isCombo ? 122 : 296,
    }
  },
  props: {
    itemImage: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    isBackgroundColor: {
      type: Boolean,
      default: false,
    },
    isCombo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    skeletonStyle() {
      return {
        '--skeleton-width': `${this.skeletonWidth}px`,
        '--skeleton-height': `${this.skeletonHeight}px`,
      }
    },
  },
  mounted() {
    
  },
  methods: {
    parseCmsContentText,
    getBannerImagesSrcSet(imageUrls) {
      return Object.entries(imageUrls)
        .map(([key, url]) => `${url} ${key === 'normal' ? '1x' : '2x'}`)
        .join(', ')
    },
    onImageLoad() {
      this.isImageLoaded = true
    },
  },
}
