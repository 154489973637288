import axios from 'axios'
import { getOrderDetailsNormalized } from '../normalizer'
import wcs from './index'

export const postOrderDetails = async (userId, orderId) => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const useMockData = wcs.getEnvConfig('useMockData') || false
  const postOrderDetailsUrl =
    useMockData == 'true'
      ? wcs.getEndPoint('getOrderDetails')
      : `${domain}/wcs/resources/store/${storeId}/ordercst/details`

  let data = {}

  try {
    data = await axios.post(postOrderDetailsUrl, {customerEmail: userId, orderId})
    return getOrderDetailsNormalized(data, userId)
  } catch (error) {
    console.log(error)
    return error.response
  }
}

export const getOrderDetails = async (userId, orderId, uuid) => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const useMockData = wcs.getEnvConfig('useMockData') || false
  const getOrderDetailUrl =
    uuid
      ? `${domain}/wcs/resources/store/${storeId}/ordercst/${uuid}/details`
      : `${domain}/wcs/resources/store/${storeId}/ordercst/${orderId}/details?customerEmail=${userId}&langId=${langId}`
  const url =
    useMockData == 'true'
      ? wcs.getEndPoint('getOrderDetails')
      : getOrderDetailUrl

  let data = {}

  try {
    data = await axios.get(url)
    return getOrderDetailsNormalized(data, userId)
  } catch (error) {
    console.log(error)
    return error.response
  }
}

export const postReturn = async parameters => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId } = wcs.getStoreConfig()
  const createReturnUrl = `${domain}/wcs/resources/store/${storeId}/returns/createReturn`
  let data = {}

  await axios
    .post(createReturnUrl, parameters)
    .then(response => {
      data = response.data
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response.data)
      } else if (error.request) {
        console.log(error.request)
      } else {
        console.log('Error', error.message)
      }
    })

  return data
}

export const getProductsRefundableAmount = async (orderId, items) => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId } = wcs.getStoreConfig()
  const getProductsRefundableAmountURL = `${domain}/wcs/resources/store/${storeId}/returns/amount/${orderId}`
  const queryParams = new URLSearchParams()
  let data = {}
  items.forEach(item => queryParams.append('orderItemId', item))
  let params = { params: queryParams }
  await axios.get(getProductsRefundableAmountURL, params).then(
    res => (data = res.data),
    error => {
      throw new Error('Error on returns/amount api in file order-details.js at line 56', {
        cause: error,
      })
    }
  )
  return data
}

export const saveAddress = async parameters => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId, catalogId } = wcs.getStoreConfig()
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const nickname =
    today.getFullYear() +
    mm +
    dd +
    today.getHours() +
    today.getMinutes() +
    today.getSeconds() +
    today.getMilliseconds()
  const authToken = await getAuthToken()

  const params = new URLSearchParams()
  params.append('storeId', storeId)
  params.append('catalogId', catalogId)
  params.append('langId', langId)
  params.append('addressType', 'ShippingAndBilling')
  params.append('authToken', authToken)
  params.append('nickName', nickname)
  params.append('email1', parameters.email1)
  params.append('firstName', parameters.firstName)
  params.append('lastName', parameters.lastName)
  params.append('phone1', parameters.phone1)
  params.append('address1', parameters.addressLine)
  params.append('zipCode', parameters.zipCode)
  params.append('city', parameters.city)
  params.append('country', parameters.country)
  //SGHDP-15223 if EMEA don't pass state as breaks BE
  if (['GB', 'DE', 'FR', 'ES', 'NL'].includes(parameters.country)) {
    params.append('state', '-')
  } else {
    params.append('state', parameters.state)
  }
  if (parameters.country === 'mx') {
    params.append('address2', parameters.municipio)
    params.append('address3', parameters.colonia)
  }
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }

  const url = `${domain}/AjaxPersonChangeServiceAddressAdd`
  let data = {}

  await axios.post(url, params, config).then(
    response => {
      data = response.data
    },
    error => {
      console.log(error)
    }
  )

  return data
}

export const getAuthToken = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId, catalogId } = wcs.getStoreConfig()
  const url = `${domain}/webapp/wcs/stores/servlet/GetCSRFAuthToken?storeId=${storeId}&catalogId=${catalogId}&langId=${langId}&requesttype=ajax`

  let data = {}

  await axios.get(url).then(
    response => {
      data = JSON.parse(response.data.replace(/(\/\*|\*\/)/g, '')).authToken
    },
    error => {
      console.log(error)
    }
  )

  return data
}

export const getOrderLabel = async url => {
  let data = {}

  await axios
    .get(url)
    .then(response => {
      data = response.data
    })
    .catch(() => {
      data.error = true
    })

  return data
}

export const zipcodeAutocomplete = async zipcode => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId } = wcs.getStoreConfig()
  const url = `${domain}/wcs/resources/store/${storeId}/zipcodeautocomplete/${zipcode}/simple`
  let data = {}

  await axios.get(url).then(
    response => {
      data = response.data.locationDetails
    },
    error => {
      console.log(error)
    }
  )
  return data
}

export const downloadInvoiceFile = async orderId => {
  let data = {}
  const { storeId } = wcs.getStoreConfig()
  const url = `/wcs/resources/store/${storeId}/invoice/${orderId}/downloadInvoiceBySAP`

  await axios
    .get(url, {
      responseType: 'blob',
    })
    .then(response => {
      data = window.URL.createObjectURL(new Blob([response.data]))
    })
    .catch(() => {
      data.error = true
    })

  return data
}
