import { UiStateType } from '../types/ui.types'
import { uniq } from 'lodash'


export const UPDATE_TOGGLE_POPUP = 'UPDATE_TOGGLE_POPUP'
export const UPDATE_SHOW_LOADER = 'UPDATE_SHOW_LOADER'
export const UPDATE_TOGGLE_POPUP_ID = 'UPDATE_TOGGLE_POPUP_ID'
export const UPDATE_TOGGLE_PANEL_ID = 'UPDATE_TOGGLE_PANEL_ID'
export const UPDATE_POPUP_PROP = 'UPDATE_POPUP_PROP'
export const UPDATE_TOAST_STATUS = 'UPDATE_TOAST_STATUS'
export const UPDATE_MINICART_STATUS = 'UPDATE_MINICART_STATUS'
export const UPDATE_MOBILE_MENU_STATUS = 'UPDATE_MOBILE_MENU_STATUS'
export const UPDATE_SUBMENU_SECTIONS = 'UPDATE_SUBMENU_SECTIONS'
export const UPDATE_OPEN_SUBMENU_SECTION = 'UPDATE_OPEN_SUBMENU_SECTION'
export const UPDATE_CLOSE_SUBMENU_SECTION = 'UPDATE_CLOSE_SUBMENU_SECTION'
export const UPDATE_SUBMENU_SECTION_VISIBLE = 'UPDATE_SUBMENU_SECTION_VISIBLE'
export const UPDATE_TREND_ANCHOR_BUTTONS_ACTIVE_STATE = 'UPDATE_TREND_ANCHOR_BUTTONS_ACTIVE_STRATE'

export const mutations = {
  [UPDATE_SHOW_LOADER]: function (state: UiStateType, payload: boolean) {
    state.showLoader = payload
  },
  [UPDATE_TOGGLE_POPUP]: function (state: UiStateType, payload: boolean) {
    state.isModalOpen = payload
  },
  [UPDATE_TOGGLE_POPUP_ID]: function (
    state: UiStateType,
    payload: {
      popupId: string,
      open?: boolean
    }
  ) {
    try {
      const { popupId, open } = payload
      const actualPopupState = state.popups[popupId]
      const realopen = open !== undefined ? open : !actualPopupState?.open || false
      state.popups = !!actualPopupState
        ? {
            ...state.popups,
            ...{
              [popupId]: {
                ...actualPopupState,
                open: realopen,
              },
            },
          }
        : state.popups
    } catch (e) {
      throw new Error('Error updating popups state', e)
    }
  },
  [UPDATE_POPUP_PROP]: function (
    state: UiStateType,
    payload: {
      popupId: string,
      props?: Record<string, string | boolean>
    }
  ) {
    try {
      const { popupId, props } = payload
      state.popups = !!state.popups[popupId]
        ? {
            ...state.popups,
            ...{
              [popupId]: {
                ...(state.popups[popupId] || {}),
                props: props || {},
              },
            },
          }
        : state.popups
    } catch (e) {
      throw new Error('Error updating popups state props', e)
    }
  },
  [UPDATE_TOGGLE_PANEL_ID]: function (
    state: UiStateType,
    payload: {
      panelId: string,
      open?: boolean
    }
  ) {
    try {
      const { panelId, open } = payload
      state.panels = !!state.panels[panelId]
        ? {
            ...state.panels,
            ...{
              [panelId]: {
                ...(state.panels[panelId] || {}),
                open: open || !state.panels[panelId].open || false,
              },
            },
          }
        : state.panels
    } catch (e) {
      throw new Error('Error updating panels state', e)
    }
  },
  [UPDATE_TOAST_STATUS]: function (
    state: UiStateType,
    payload: {
      toastId: string,
      open?: boolean
    }
  ) {
    try {
      const { toastId, open } = payload
      state.toasts = !!state.toasts[toastId]
        ? {
            ...state.toasts,
            ...{
              [toastId]: {
                ...(state.toasts[toastId] || {}),
                open: open || !state.toasts[toastId].open || false,
              },
            },
          }
        : state.toasts
    } catch (e) {
      throw new Error('Error updating toasts state', e)
    }
  },
  [UPDATE_MINICART_STATUS]: function (
    state: UiStateType,
    payload?: {
      open?: boolean
    }
  ) {
    try {
      const { open } = payload || {}
      state.miniCart = {
        open: open || !state.miniCart.open || false,
      }
    } catch (e) {
      throw new Error('Error updating minicart state', e)
    }
  },
  [UPDATE_MOBILE_MENU_STATUS]: function (
    state: UiStateType,
    payload?: {
      open?: boolean
    }
  ) {
    try {
      const { open } = payload || {}
      state.menu = {
        ...state.menu,
        mobileMenuOpen: open|| !state.menu.mobileMenuOpen || false,
      }
    } catch (e) {
      throw new Error('Error updating mobile menu state', e)
    }
  },
  [UPDATE_SUBMENU_SECTIONS]: function (
    state: UiStateType,
    payload?: {
      sectionId?: string,
      open?: boolean
    }
  ) {
    try {
      const { sectionId } = payload || {}
      const actualSectionState = state.menu.openSubMenus
      let updatedSectionState = []
      if (actualSectionState.includes(sectionId) && !!sectionId) {
        updatedSectionState = actualSectionState.filter(section => section !== sectionId)
      } else {
        updatedSectionState = uniq([...actualSectionState.filter(section => section === sectionId), sectionId])
      }
      state.menu.openSubMenus = updatedSectionState
    } catch (e) {
      throw new Error('Error updating menu submenus state', e)
    }
  },
  [UPDATE_OPEN_SUBMENU_SECTION]: function (
    state: UiStateType,
    payload?: {
      sectionId?: string,
      isFirstLevel?: boolean
    }
  ) {
    try {
      const { sectionId,isFirstLevel } = payload || {}
      const actualSectionState = state.menu.openSubMenus
      let updatedSectionState = [...actualSectionState]
      if (!!sectionId) {
        updatedSectionState = uniq([...actualSectionState, sectionId])
      }
      // if it is a first level section, we remove all other sections
      if (!!sectionId && isFirstLevel) {
        updatedSectionState = uniq([...actualSectionState, sectionId]).filter(section => section === sectionId)
      }
      state.menu.openSubMenus = updatedSectionState
    } catch (e) {
      throw new Error('Error adding menu submenu state', e)
    }
  },
  [UPDATE_CLOSE_SUBMENU_SECTION]: function (
    state: UiStateType,
    payload?: {
      sectionId?: string
    }
  ) {
    try {
      const { sectionId } = payload || {}
      const actualSectionState = state.menu.openSubMenus
      state.menu.openSubMenus = actualSectionState.filter(section => section !== sectionId)
    } catch (e) {
      throw new Error('Error removing menu submenu state', e)
    }
  },
  [UPDATE_SUBMENU_SECTION_VISIBLE]: function (
    state: UiStateType,
    payload?: {
      visible?: boolean
    }
  ) {
    try {
      const { visible } = payload || {}
      state.menu.mobileSubMenuPanelOpen = visible || !state.menu.mobileSubMenuPanelOpen || false
    } catch (e) {
      throw new Error('Error removing menu submenu state', e)
    }
  },
  [UPDATE_TREND_ANCHOR_BUTTONS_ACTIVE_STATE]: function(
    state: UiStateType,
    payload: number
  ) {
    try {
      state.cmsModules.selectedTrendsAnchorsButton = payload
    } catch(error) {
      throw new Error('Update selected anchor failed', error)
    }
  },
}