

import { createNamespacedHelpers } from 'vuex'
import { ACTION_SUBMENU_SECTION_VISIBLE, ACTION_UPDATE_MOBILE_MENU_STATUS } from '@store/actions/ui'

const { mapActions: mapActionsUI } = createNamespacedHelpers('ui')
export default {
  name: 'SgSubMenu',
  props:{
    expanded: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  computed: {

    isMobile() {
      return this.$mq === 'tablet' || this.$mq === 'tabletLandscape'
    },

  },
  methods: {
    ...mapActionsUI({
      toggleMobileSubMenuVisible: ACTION_SUBMENU_SECTION_VISIBLE,
    }),
    onBackBtnClicked() {
      this.$emit('back')
    },
  },
}
