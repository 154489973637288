import Vue from 'vue'
import VueI18n from 'vue-i18n'
//import sharedMessages from './locales/en_US'
//@TODO implement dynamic globalmessages load

Vue.use(VueI18n)

export const DEFAULT_LOCALE = 'en_US'

export const i18n = new VueI18n({
  locale: window.wcs_config ? window.wcs_config.locale : DEFAULT_LOCALE, // set locale from WCS config
  fallbackLocale: DEFAULT_LOCALE,
  missing: (locale, key) => {
    return ''
  },
  //sharedMessages: sharedMessages,
})

const loadedSections = ['global'] // global labels shared between all components

/**
 * This function loads the translations to be used in the specified component
 * @param {*} section the section name, that should correspond to the
 * section sub-directory in the locale folder ex: 'store-locator'
 */
export async function loadSectionTranslations(section) {
  const selectedLocale = i18n.locale
  // If the language was already loaded
  if (loadedSections.includes(section)) {
    return
  }

  // If the section language hasn't been loaded yet
  try {
    const messages = await import(/* webpackChunkName: "lang-[request]" */ `./locales/${section}/${selectedLocale}.js`)

    if (messages) i18n.mergeLocaleMessage(selectedLocale, messages.default)

    if (selectedLocale !== DEFAULT_LOCALE) {
      // load also default locale for fallback
      const defaultLocaleMessages = await import(/* webpackChunkName: "lang-[request]" */ `./locales/${section}/en_US.js`)
      i18n.mergeLocaleMessage('en_US', defaultLocaleMessages.default)
    }
    loadedSections.push(section)
  } catch (e) {
    // load also default locale for fallback
    const defaultLocaleMessages = await import(/* webpackChunkName: "lang-[request]" */ `./locales/${section}/en_US.js`)
    i18n.mergeLocaleMessage('en_US', defaultLocaleMessages.default)
  }
}

export default i18n
