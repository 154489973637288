
import { createNamespacedHelpers } from 'vuex'
import SghMenuItem from '@components/navigation/menu-item.vue'
import SghSubMenu from '@components/navigation/sub-menu.vue'
import SghCmsSubMenuList from '@components/cms-modules/ddm-modules/sub-components/sub-menu-list.vue'
import SghCmsDdmLayout from '@components/cms-modules/ddm-modules/layouts/ddm-layout.vue'
import SghCmsPlacementSwitch from '@components/cms-modules/ddm-modules/placement-switch.vue'

const { mapGetters: mapGettersUi } = createNamespacedHelpers('ui')
const { mapGetters: mapGettersDdm } = createNamespacedHelpers('ddm')
export default {
  name: 'SghMainNavigation',
  components: {
    SghCmsPlacementSwitch,
    SghCmsSubMenuList,
    SghCmsDdmLayout,
    SghMenuItem,
    SghSubMenu,
  },

  computed: {
    ...mapGettersUi(['menu']),
    ...mapGettersDdm(['mainMenu']),

    isMobile() {
      return this.$mq === 'tablet' || this.$mq === 'tabletLandscape'
    },
  },

  methods: {
    getColumnsCount(subMenu) {
      const menuListCount = subMenu?.menuLists?.length
      const placementCount = subMenu?.combobanner?.length || 0
      return menuListCount + placementCount
    },
  },
}
