export const ACTION_LOAD_ORDER_DETAILS = 'ACTION_LOAD_ORDER_DETAILS'
export const ACTION_POST_ORDER_DETAILS = 'ACTION_POST_ORDER_DETAILS'
export const ACTION_UPDATE_ORDER_ITEM_CHECKED = 'ACTION_UPDATE_ORDER_ITEM_CHECKED'
export const ACTION_ORDER_DELIVERY_CHECKED = 'ACTION_ORDER_DELIVERY_CHECKED'
export const ACTION_ORDER_DELIVERY_EMPTY = 'ACTION_ORDER_DELIVERY_EMPTY'
export const ACTION_CREATE_RETURN = 'ACTION_CREATE_RETURN'
export const ACTION_SAVE_RETURN_DATA = 'ACTION_SAVE_RETURN_DATA'
export const ACTION_REASON_SELECT = 'ACTION_REASON_SELECT'
export const ACTION_NEW_ADDRESS_SAVE = 'ACTION_NEW_ADDRESS_SAVE'
export const ACTION_CREATE_ADDRESS = 'ACTION_CREATE_ADDRESS'
export const ACTION_ERROR_MESSAGE_EMPTY = 'ACTION_ERROR_MESSAGE_EMPTY'
export const ACTION_RETURN_FLOW_STEP = 'ACTION_RETURN_FLOW_STEP'
export const ACTION_GET_ORDER_LABEL = 'ACTION_GET_ORDER_LABEL'
export const ACTION_GET_MX_ZIPECODE_AUTOCOMPLETE = 'ACTION_GET_MX_ZIPECODE_AUTOCOMPLETE'
export const ACTION_GET_DOWNLOAD_INVOICE_FILE = 'ACTION_GET_DOWNLOAD_INVOICE_FILE'
export const ACTION_GET_ORDER_ITEMS_REFUND_AMOUNT = 'ACTION_GET_ORDER_ITEMS_REFUND_AMOUNT'

import {
  UPDATE_ORDER_DETAILS,
  UPDATE_ORDER_ITEM_CHECKED,
  UPDATE_ORDER_DELIVERY_CHECKED,
  UPDATE_ORDER_DELIVERY_EMPTY,
  UPDATE_SAVE_RETURN_DATA,
  UPDATE_NEW_ORDER_DATA,
  UPDATE_SAVE_EMAIL,
  UPDATE_REASON_SELECT,
  UPDATE_NEW_ADDRESS,
  UPDATE_ERROR_MESSAGE,
  UPDATE_RETURN_FLOW_STEP,
  UPDATE_ERROR_MESSAGEFORM,
  UPDATE_URL_ORDER_LABEL,
  UPDATE_ERROR_MESSAGE_RETURN_LABEL,
  UPDATE_ZIPCODE_AUTOCOMPLETE,
  UPDATE_EMPTY_ZIPCODE_AUTOCOMPLETE,
  UPDATE_ERROR_DOWNLOAD_INVOICE_FILE,
  UPDATE_INVOICE_FILE,
  UPDATE_ORDER_ITEM_REFUND_AMOUNT,
} from '../mutations/order'

import {
  downloadInvoiceFile,
  getOrderDetails,
  postOrderDetails,
  getOrderLabel,
  getProductsRefundableAmount,
  postReturn,
  saveAddress,
  zipcodeAutocomplete,
} from '../../libs/wcs/order-details'

export const actions = {
  [ACTION_LOAD_ORDER_DETAILS]: async function({ commit }, { email, orderId, uuid }) {
    const data = await getOrderDetails(email, orderId, uuid)
    if (data.items !== undefined) {
      commit(UPDATE_ORDER_DETAILS, data)
      commit(UPDATE_SAVE_EMAIL, decodeURIComponent(email))
    } else {
      commit(UPDATE_ERROR_MESSAGEFORM, data)
    }
  },
  [ACTION_POST_ORDER_DETAILS]: async function({ commit }, { email, orderId }) {
    const data = await postOrderDetails(email, orderId)
    if (data.items !== undefined) {
      commit(UPDATE_ORDER_DETAILS, data)
      commit(UPDATE_SAVE_EMAIL, decodeURIComponent(email))
    } else {
      commit(UPDATE_ERROR_MESSAGEFORM, data)
    }
  },
  [ACTION_UPDATE_ORDER_ITEM_CHECKED]: async function({ commit }, item) {
    commit(UPDATE_ORDER_ITEM_CHECKED, item)
  },
  [ACTION_ORDER_DELIVERY_CHECKED]: async function({ commit }, address) {
    commit(UPDATE_ORDER_DELIVERY_CHECKED, address)
  },
  [ACTION_ORDER_DELIVERY_EMPTY]: async function({ commit }, address) {
    commit(UPDATE_ORDER_DELIVERY_EMPTY, address)
  },
  [ACTION_SAVE_RETURN_DATA]: async function({ commit }, item) {
    commit(UPDATE_SAVE_RETURN_DATA, item)
  },
  [ACTION_REASON_SELECT]: async function({ commit }, reason) {
    commit(UPDATE_REASON_SELECT, reason)
  },
  [ACTION_CREATE_RETURN]: async function({ commit }, parameters) {
    const data = await postReturn(parameters)
    data && Object.keys(data).length === 0 && data.constructor === Object
      ? commit(UPDATE_ERROR_MESSAGE, true)
      : commit(UPDATE_NEW_ORDER_DATA, data)
  },
  [ACTION_NEW_ADDRESS_SAVE]: async function({ commit }, p_newAddress) {
    commit(UPDATE_NEW_ADDRESS, p_newAddress)
  },
  [ACTION_CREATE_ADDRESS]: async function({ commit }, parameters) {
    const data = await saveAddress(parameters)
    data && Object.keys(data).length === 0 && data.constructor === Object
      ? commit(UPDATE_ERROR_MESSAGE, true)
      : commit(UPDATE_ORDER_DELIVERY_CHECKED, data.addressId[0])
  },
  [ACTION_ERROR_MESSAGE_EMPTY]: async function({ commit }) {
    commit(UPDATE_ERROR_MESSAGE, false)
  },
  [ACTION_RETURN_FLOW_STEP]: async function({ commit }, value) {
    commit(UPDATE_RETURN_FLOW_STEP, value)
  },
  [ACTION_GET_ORDER_LABEL]: async function({ commit }, url) {
    const data = await getOrderLabel(url)
    data.error
      ? commit(UPDATE_ERROR_MESSAGE_RETURN_LABEL, data.error)
      : commit(UPDATE_URL_ORDER_LABEL, data.labelImage)
  },
  [ACTION_GET_MX_ZIPECODE_AUTOCOMPLETE]: async function({ commit }, zipcode) {
    const data = await zipcodeAutocomplete(zipcode)
    data.colony.length != 0
      ? commit(UPDATE_ZIPCODE_AUTOCOMPLETE, data)
      : commit(UPDATE_EMPTY_ZIPCODE_AUTOCOMPLETE)
  },
  [ACTION_GET_DOWNLOAD_INVOICE_FILE]: async function({ commit }, orderId) {
    const data = await downloadInvoiceFile(orderId)
    data.error
      ? commit(UPDATE_ERROR_DOWNLOAD_INVOICE_FILE, data.error)
      : commit(UPDATE_INVOICE_FILE, data)
  },
  [ACTION_GET_ORDER_ITEMS_REFUND_AMOUNT]: async function({ commit, state }) {
    let items = []
    const responseItems = []
    const orderId = state.orderDetails.number
    state.returnDetails.itemsChecked.forEach(item => items.push(item.id))
    const data = await getProductsRefundableAmount(orderId, items)
    for (let item in data) responseItems.push(data[item])
    data.error
      ? commit(UPDATE_ERROR_MESSAGE, data.error)
      : commit(UPDATE_ORDER_ITEM_REFUND_AMOUNT, responseItems[0])
  },
}
