export const ACTION_LOAD_MINICART = 'ACTION_LOAD_MINICART'
export const ACTION_LOAD_MINICART_ESPOT = 'ACTION_LOAD_MINICART_ESPOT'
export const ACTION_LOAD_ALGOLIA_MINICART_SUGGESTION = 'ACTION_LOAD_ALGOLIA_MINICART_SUGGESTION'
export const ACTION_LOAD_MINICART_PROMO = 'ACTION_LOAD_MINICART_PROMO'
export const ACTION_LOAD_ALGOLIA_FREQUENTLY_BOUGHT_TOGETHER =
  'ACTION_LOAD_ALGOLIA_FREQUENTLY_BOUGHT_TOGETHER'
export const ACTION_RESET_MINICART =
  'ACTION_RESET_MINICART'

import {
  UPDATE_MINICART,
  UPDATE_MINICART_ESPOT,
  UPDATE_MINICART_SUGGESTIONS,
  UPDATE_MINICART_PROMO, UPDATE_RESET_MINICART,
} from '../mutations/minicart'

import {
  algoliaGetSuggestion,
  algoliaGetFrequentlyBoughtTogether,
} from '../../libs/utils/algoliaIntegration'
import { getAlgoliaMinicartSuggestionNormalized } from '../../libs/algolia-normalizer'
import isEmpty from 'lodash/isEmpty'

import {
  getMinicart,
  getMinicartPromotion,
  getNumberitem,
} from '../../libs/wcs/minicart'

import { updateCounterMinicart } from '../../libs/utils/utilsMinicart'

export const actions = {
  [ACTION_LOAD_MINICART]: async function({ commit }, { productId, minicartProducts = null }) {
    localStorage.setItem('savedCartState', JSON.stringify(minicartProducts.orderItems))
    const data = await getMinicart(minicartProducts, productId)
    const counter = data.records
    updateCounterMinicart(counter)
    commit(UPDATE_MINICART, { data, counter })
  },
  [ACTION_LOAD_ALGOLIA_MINICART_SUGGESTION]: async function({ commit }, productId) {
    const dataSuggestions = await algoliaGetSuggestion(productId).then(result => {
      if (!isEmpty(result.hits)) {
        return getAlgoliaMinicartSuggestionNormalized(result.hits)
      }
    })
    commit(UPDATE_MINICART_SUGGESTIONS, dataSuggestions)
  },
  [ACTION_LOAD_ALGOLIA_FREQUENTLY_BOUGHT_TOGETHER]: async function({ commit }, productId) {
    const dataSuggestions = await algoliaGetFrequentlyBoughtTogether(productId).then(result => {
      if (!isEmpty(result.hits)) {
        return getAlgoliaMinicartSuggestionNormalized(result.hits)
      }
    })
    commit(UPDATE_MINICART_SUGGESTIONS, dataSuggestions)
  },
  [ACTION_LOAD_MINICART_PROMO]: async function({ commit }, upcProduct) {
    const data = await getMinicartPromotion(upcProduct)
    commit(UPDATE_MINICART_PROMO, data)
  },
  [ACTION_RESET_MINICART]: async function({ commit }) {
    commit(UPDATE_RESET_MINICART)
  },
}
