
import SghCmsCollection from './sub-components/collection.vue'
import SghCmsSingleBanner from './sub-components/single-banner.vue'
import { parseCmsContentText } from '@libs/utils/cms'

export default {
  name: 'SghCmsPlacementSwitch',
  components: { SghCmsSingleBanner, SghCmsCollection },
  props: {
    title: {
      type: String,
      default: '',
    },
    placements: {
      type: Array,
      required: true,
    },
    banners: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    captions() {
      return this.placements
        ?.filter(placement => placement.id === 'singlebanner')
        ?.map((placement) => {
          return placement.images?.map(image => image?.caption)?.pop()
        })
    },
    footerColumnsCount() {
      return this.captions?.length || 1
    },
    isSinglePlacement() {
      return this.placements?.length === 1
    },
  },
  methods: {
    parseCmsContentText,
    getPlacement(id) {
      switch (id) {
        case 'singlebanner':
          return 'SghCmsSingleBanner'
        case 'collection':
          return 'SghCmsCollection'
        default:
          return null
      }
    },
  },
}
