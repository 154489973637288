import attempt from 'lodash/attempt'
import isError from 'lodash/isError'

export const sameUrl = (a, b) => {
  const nA = decodeURIComponent(a)
  const nB = decodeURIComponent(b)
  // console.log(nA, nB)
  return nA === nB || nA.endsWith(nB) || nA.includes(nB)
}

//match param with category link chunk
export const checkUrlParameter = (param, url, link) => {
  //prepare regex
  param = param.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  var regex = new RegExp('[\\?&]' + param + '=([^&]*)')
  //alt: var regex = new RegExp('[?&]" + param + "=([^&]+).*$')
  let matchedChunk
  if (regex.exec(url)) {
    //get url param and decode:
    matchedChunk = decodeURIComponent(regex.exec(url)[1].replace(/\+/g, ' '))
    //get relevant link chunk and decode:
    link = link.substring(0, link.indexOf('?'))
    link = decodeURIComponent(
      link
        .split('/')
        .pop()
        .replace(/\+/g, ' ')
    )
  }
  if (regex.exec(url) && matchedChunk == link) {
    return true
  }
  return false
}

export const formatPrice = price => {
  const regex = new RegExp('(\\d+)?[\\.,\\s]?(\\d+)[\\.,](\\d{2})')
  return price.toString().replace(regex, '$1$2.$3')
}

export const getPropertyByPath = (obj, path) => {
  const keys = path.split('.')
  let value = obj

  for (const key of keys) {
    value = value[key]

    if (value === undefined) {
      return undefined
    }
  }

  return value
}

//return a parsed json if item is valid and not empty JSON otherwise return false
export const safeJSONParse = (item,context = '') => {
  const res = attempt(JSON.parse, item)
  if (isError(res)) {
    context != ''
    ? console.error(`Error parsing JSON for ${context} section:`, res)
    : console.error('Error parsing JSON:', res)
    return false
  }
  else if (!res) return false
  else return JSON.parse(item) || false
}

const tryParseJSON = (string) => {
  try {
    const normalizedString = string.replace(/'/g, '"').replace(/\n/g, '')
    const parsed = JSON.parse(normalizedString)
    return (typeof parsed === 'object' && parsed !== null) ? parsed : null
  } catch (e) {
    return null
  }
}

export const parseValue = string => {
  // Try parsing as number
  let parsedNumber = parseFloat(string)
  if (!isNaN(parsedNumber)) return parsedNumber

  // Try parsing as boolean
  if (string.toLowerCase() === 'true' || string.toLowerCase() === 'false')
    return string.toLowerCase() === 'true'

  // Try parsing as JSON object
  const parsedObject = tryParseJSON(string)
  if (parsedObject) return parsedObject

  // Return the original string if it's none of the above
  return string
}
