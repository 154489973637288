import { actions } from '../actions/ddm'
import { getters } from '../getters/ddm'
import { mutations } from '../mutations/ddm'

export const ddm = {
  namespaced: true,
  state: {
   mainMenu:[],
  },
  actions,
  getters,
  mutations,
}
