import { safeJSONParse } from '../../libs/utils/string'
import { getCurrentCountry } from '../../libs/utils/currentCountry'

import {
  getWishlistProducts,
  wcs_add_wishlist_product,
  wcs_remove_wishlist_product,
  postEmailMe,
  postEmailSubscription,
  getCounterWishlist,
  addToBagCustomized,
  getWishlistProductsCatentryArray,
} from '../../libs/wcs/wishlist'

import {
  UPDATE_LOAD_WISHLIST_PRODUCTS,
  UPDATE_IS_SHARED_WISHLIST,
  UPDATE_ERROR_EMAIL_ME,
  UPDATE_EMAIL_ME_RESET_ERROR,
  UPDATE_COUNT_WISHLIST_ITEM,
  UPDATE_REMOVE_FROM_WISHLIST,
  UPDATE_ERROR_ADD_TO_BAG_WISHLIST_CUSTOMIZED,
  UPDATE_WISHLIST_EMAIL_SUBSCRIPTION,
  UPDATE_WISHLIST_LIGHT_DATA,
} from '../mutations/wishlist'

export const ACTION_LOAD_WISHLIST = 'ACTION_LOAD_WISHLIST'
export const ACTION_ADD_TO_WISHLIST = 'ACTION_ADD_TO_WISHLIST'
export const ACTION_REMOVE_FROM_WISHLIST = 'ACTION_REMOVE_FROM_WISHLIST'
export const ACTION_WISHLIST_EMAIL_ME = 'ACTION_WISHLIST_EMAIL_ME'
export const ACTION_WISHLIST_MARKETING = 'ACTION_WISHLIST_MARKETING'
export const ACTION_WISHLIST_RESET_ERROR = 'ACTION_WISHLIST_RESET_ERROR'
export const ERROR_ADD_TO_WISHLIST = 'ERROR_ADD_TO_WISHLIST'
export const ERROR_REMOVE_FROM_WISHLIST = 'ERROR_REMOVE_FROM_WISHLIST'
export const ACTION_COUNT_WISHLIST_ITEM = 'ACTION_COUNT_WISHLIST_ITEM'
export const ACTION_REMOVE_FROM_WISHLIST_STATE = 'ACTION_REMOVE_FROM_WISHLIST_STATE'
export const ACTION_ADD_TO_BAG_WISHLIST_CUSTOMIZED = 'ACTION_ADD_TO_BAG_WISHLIST_CUSTOMIZED'
export const ACTION_LOAD_WISHLIST_LIGHT = 'ACTION_LOAD_WISHLIST_LIGHT'

export const actions = {

  [ACTION_LOAD_WISHLIST]: async function({ commit }, payload = {}) {
  
    const { sortBy, listId } = payload
    let data = await getWishlistProducts({ sortBy, listId })

    if (data) {
      commit(UPDATE_LOAD_WISHLIST_PRODUCTS, { ...data })
    }
    if (listId) {
      commit(UPDATE_IS_SHARED_WISHLIST, true)
    }
  },

  [ACTION_ADD_TO_WISHLIST]: async function({ dispatch, commit }, { catentryId }) {
    try {
      return wcs_add_wishlist_product({ catentryId }).then(res => {
        commit(UPDATE_WISHLIST_LIGHT_DATA, res[0]?.catEntryId)
        getCounterWishlist().then(counter => commit(UPDATE_COUNT_WISHLIST_ITEM, counter))
        return res
      })
    } catch (e) {
      return dispatch(ERROR_ADD_TO_WISHLIST, e)
    }
  },

  [ERROR_ADD_TO_WISHLIST]: function(error) {
    throw new Error('Error adding item to wishlist', error)
  },

  [ACTION_REMOVE_FROM_WISHLIST]: async function(
    { dispatch, commit },
    { catentryId, wishlistRelatedLinks }
  ) {
    try {
      return wcs_remove_wishlist_product(catentryId).then(res => {
        if(res) {
          commit(UPDATE_WISHLIST_LIGHT_DATA, res[0]?.catEntryId)
        }
        getCounterWishlist().then(data => {
          !data.error ? commit(UPDATE_COUNT_WISHLIST_ITEM, data) : null
        })
        return res
      })
    } catch (e) {
      dispatch(ERROR_REMOVE_FROM_WISHLIST, e)
    }
    throw new Error('%s payload %o', ACTION_REMOVE_FROM_WISHLIST, { catentryId })
  },

  [ACTION_REMOVE_FROM_WISHLIST_STATE]: async function({ commit }, { catentryId }) {
    commit(UPDATE_REMOVE_FROM_WISHLIST, catentryId)
  },

  [ERROR_REMOVE_FROM_WISHLIST]: function(store, error) {
    throw new Error('%s payload %o', ERROR_REMOVE_FROM_WISHLIST, store, error)
  },

  [ACTION_WISHLIST_EMAIL_ME]: async function({ commit }, parameters) {
    const data = await postEmailMe(parameters)
    data.error ? commit(UPDATE_ERROR_EMAIL_ME, data.error) : commit(UPDATE_EMAIL_ME_RESET_ERROR)
  },

  [ACTION_WISHLIST_MARKETING]: async function({ commit }, parameters) {
    const data = await postEmailSubscription(parameters)
    !data.error ? commit(UPDATE_WISHLIST_EMAIL_SUBSCRIPTION, data) : null
  },

  [ACTION_WISHLIST_RESET_ERROR]: async function({ commit }) {
    commit(UPDATE_EMAIL_ME_RESET_ERROR)
  },

  [ACTION_COUNT_WISHLIST_ITEM]: async function({ commit }) {
    const data = await getCounterWishlist()
    !data.error ? commit(UPDATE_COUNT_WISHLIST_ITEM, data) : null
  },

  [ACTION_ADD_TO_BAG_WISHLIST_CUSTOMIZED]: async function({ commit }, parameters) {
    const data = await addToBagCustomized(parameters.url, parameters.params)
    if (data.error) commit(UPDATE_ERROR_ADD_TO_BAG_WISHLIST_CUSTOMIZED, data.error)
    return !data.error ? true : false
  },

  [ACTION_LOAD_WISHLIST_LIGHT]: async function({ commit }) {
    const itemsFromStorage = safeJSONParse(localStorage.getItem(`wishlistItems_${getCurrentCountry()}`))
    let data = null

    if (itemsFromStorage.length) {
      data = itemsFromStorage
    } else {
      data = await getWishlistProductsCatentryArray()
    }

    if (data) {
      commit(UPDATE_WISHLIST_LIGHT_DATA, data)
      commit(UPDATE_COUNT_WISHLIST_ITEM, data?.length)
    }
  },
}


