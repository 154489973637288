import { DdmStateType } from '../types/ddm.types'

export const UPDATE_CMSPLACEMENTS = 'UPDATE_CMSPLACEMENTS'
export const mutations = {
 
    [UPDATE_CMSPLACEMENTS]: function (
        state: DdmStateType,
        payload:[]
      )
      {
      state.mainMenu = payload
       
      },

}




