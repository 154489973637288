
import { createNamespacedHelpers } from 'vuex'
import { loadSectionTranslations } from '../../../../i18n-setup'
import { getCurrentCountry } from '../../../../libs/utils/currentCountry'

import { ACTION_PROFILE_DETAILS } from '../../../../store/actions/my-account'
const { mapActions, mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')


export default {
  name: 'sgh-menu-wrapper',
  data() {
    return {
      overlayLogin: false,
      loading: false,
    }
  },

  computed: {
    ...mapGettersMyAccount(['myAccountDetails', 'profileDetails']),
    loggedInState() {
      return this.myAccountDetails.loggedIn
    },

    isMobile() {
      return this.$mq == 'tabletLandscape' || this.$mq == 'tablet'
    },
    getMyAccountUrl() {
      return `/${getCurrentCountry()}/myaccount/`
    },
  },

  watch: {
    loggedInState(value) {
      if (value && !this.$router) this.stateLogged()
    },
    'profileDetails.objectPerson.registeredEmail': function (newValue) {
      window.ct_data.user.mail = newValue
    },
    'profileDetails.objectPerson.personalInfo.firstName': function (newValue) {
      window.ct_data.user.name = newValue
    },
  },

  mounted() {
    loadSectionTranslations('my-account-new')
    this.$root.$on('closeOverlay', () => (this.overlayLogin = false))
    this.$root.$on('closeOverlay', () => ((this.overlayLogin = false), (this.loading = false)))
    this.$root.$on('loading', () => (this.loading = true))
    this.$root.$on('!loading', () => (this.loading = false))
    if (this.myAccountDetails.loggedIn && !this.$router) this.stateLogged()
    window.wishlist = this
  },

  methods: {
    ...mapActions({
      actionProfileDetails: ACTION_PROFILE_DETAILS,
    }),
    stateLogged() {
      this.actionProfileDetails()
    },
    clickLoginIcon() {

      window.tealium_data2track.push({
        id: 'Click',
        data_element_id: 'MainNav_Account',
        data_description: 'Account',
      })
        //TODO: remove this when the DDM menu development is finished
        this.$root.$emit('closeMenu')
        window.location.href = this.getMyAccountUrl

    },
  },

}
