
import { ref } from 'vue'
import {
  ACTION_CLOSE_SUBMENU_SECTION,
  ACTION_OPEN_SUBMENU_SECTION,
  ACTION_SUBMENU_SECTION_VISIBLE,
  ACTION_UPDATE_SUBMENU_SECTIONS,
} from '@store/actions/ui'
import { createNamespacedHelpers } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import { parseCmsContentText } from '@libs/utils/cms'

const { mapActions, mapGetters } = createNamespacedHelpers('ui')
const { mapGetters: mapGettersDdm } = createNamespacedHelpers('ddm')
export default {
  name: 'SghMenuItem',
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    masterId: {
      type: String,
      default: null,
    },
    isHighLighted: {
      type: String,
      default: 'false',
    },
    isShopAll: {
      type: String,
      default: 'false',
    },
    badge: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      expanded: false,
      showDelay: 300,
      hideDelay: 400,
      menuEnterTimer: null,
      menuLeaveTimer: null,
      isFistLevelMenuItem: false,
    }
  },
  computed: {
    ...mapGetters(['menu']),
    ...mapGettersDdm(['mainMenu']),
    isMobile() {
      return this.$mq === 'tablet' || this.$mq === 'tabletLandscape'
    },
    itemUrl() {
      if (this.isMobile) {
        return this.hasSubMenu ? null : this.url || null
      } else {
        return this.url || null
      }
    },
    expandedSubMenu() {
      return this.menu.openSubMenus.includes(this.id)
    },
    hasBadge() {
      return !isEmpty(this.badge)
    },
    hasSubMenu() {
      const currentCmsMenuItem = this.mainMenu?.find(menuItem => menuItem.id === this.id)
      return !isEmpty(this.masterId) && !isEmpty(currentCmsMenuItem?.subMenu)
    },
  },
  mounted() {
    const parentNavigationRole = ref(false)
    const parentElement = this.$refs.menuItem?.parentElement
    parentNavigationRole.value =
      parentElement?.getAttribute('data-menu-role') === 'main' ?? parentNavigationRole.value
    this.isFistLevelMenuItem = parentNavigationRole.value
  },
  methods: {
    parseCmsContentText,
    ...mapActions({
      toggleSubMenuId: ACTION_UPDATE_SUBMENU_SECTIONS,
      expandSubMenuId: ACTION_OPEN_SUBMENU_SECTION,
      closeSubMenuId: ACTION_CLOSE_SUBMENU_SECTION,
      toggleMobileSubMenuVisible: ACTION_SUBMENU_SECTION_VISIBLE,
    }),
    onKeyDown(e) {
      if (e.keyCode === 13) {
        if (this.hasSubMenu) {
          e.preventDefault()
        }
        this.toggleSubmenu()
      }
    },
    onClick() {
      if (!this.isMobile && this.url) {
        return
      }
      this.hasSubMenu && this.toggleSubmenu()
    },
    toggleSubmenu(e) {
      clearTimeout(this.menuEnterTimer)
      this.toggleSubMenuId({ sectionId: this.id })
      this.isMobile && this.toggleMobileSubMenuVisible({ visible: true })
    },
    expandSubMenu() {
      this.expandSubMenuId({ sectionId: this.id, isFirstLevel: this.isFistLevelMenuItem })
    },
    collapseSubMenu() {
      this.closeSubMenuId({ sectionId: this.id })
    },
    mainMenuItemOver(event) {
      if (this.isMobile || !this.hasSubMenu) {
        return
      }
      // clear the opposite timer
      clearTimeout(this.menuLeaveTimer)
      // add active class after a delay
      this.menuEnterTimer = setTimeout(
        function () {
          !!this.id && this.hasSubMenu && this.expandSubMenu(event)
        }.bind(this),
        this.showDelay
      )
    },

    mainMenuItemOut(event) {
      if (this.isMobile || !this.hasSubMenu) {
        return
      }
      // clear the opposite timer
      clearTimeout(this.menuEnterTimer)
      // remove active class after a delay
      this.menuLeaveTimer = setTimeout(
        function () {
          !!this.id && this.hasSubMenu && this.collapseSubMenu(event)
        }.bind(this),
        this.hideDelay
      )
    },
  },
}
